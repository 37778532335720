<template>
  <div class="loginPage">
    <div class="login-Form">
      <el-image
        class="login-code"
        style="width: 80px; height: 80px"
        src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/login-code.png"
        @click="ddLoginInit"
        v-if="!retrievePassword && !getNodeCode"
      ></el-image>

      <!-- 手机号登录 -->
      <div class="code" v-if="!isDD && !getNodeCode && !retrievePassword">
        <h2>欢迎登录Ylab系统</h2>
        <div class="login-type">
          <span
            v-for="(item, index) in ['手机号', '账号密码']"
            :key="index"
            @click="triggerPhone(index)"
            :class="{ active: typeActive === index }"
            >{{ item }}</span
          >
        </div>
        <!-- 手机号登录 -->
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          v-if="typeActive === 0"
          style="margin-top: 40px"
        >
          <el-form-item prop="phone">
            <div class="login-phone">
              <span>+86</span>
              <el-input
                v-model="form.phone"
                autofocus
                placeholder="请输入你的手机号"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>

        <!-- 账号登录 -->
        <div class="login-password" v-else>
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item prop="phone">
              <div class="account">
                <el-image
                  style="width: 28.8px; height: 24px"
                  src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/login-phone.png"
                ></el-image>
                <el-input
                  v-model="form.phone"
                  placeholder="请输入你的手机号"
                  autofocus
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password" style="margin-top: 36px">
              <div class="password">
                <el-image
                  style="width: 28.8px; height: 24px"
                  src="https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/login-password.png"
                ></el-image>
                <el-input
                  v-model="form.password"
                  placeholder="请输入你的密码"
                  :type="passwordType"
                >
                  <template #suffix>
                    <i
                      class="el-icon-view"
                      style="margin-right: 19.2px; cursor: pointer"
                      @mouseenter="passwordType = 'text'"
                      @mouseleave="passwordType = 'password'"
                    ></i>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="typeActive === 1" class="rememberPassword">
          <span>
            <el-checkbox v-model="rememberPassword">记住密码</el-checkbox></span
          >
          <span @click="retrievePassword = true">忘记密码？</span>
        </div>
        <el-button
          style="
            width: 100%;
            height: 60px;
            color: #fff;
            font-size: 16px;
            margin-top: 40px;
          "
          :style="{
            background:
              typeActive === 0 && !/^1[3456789]\d{9}$/.test(form.phone)
                ? '#bbbfc4'
                : typeActive === 1 && form.password.length < 1
                ? '#bbbfc4'
                : '#2E98FE',
          }"
          @click="submitForm"
          >{{ typeActive === 0 ? "下一步" : "登录" }}</el-button
        >

        <div style="margin-top: 19.2px">
          <el-checkbox v-model="read" style="color: #344666"> </el-checkbox>
          <span>
            我已阅读并同意<span
              @click.stop.prevent="
                text = '服务协议';
                drawer = true;
              "
              style="color: #2e98fe; cursor: pointer"
              >服务协议</span
            >和<span
              style="color: #2e98fe; cursor: pointer"
              @click.stop.prevent="
                text = '隐私政策';
                drawer = true;
              "
              >隐私政策</span
            >
          </span>
        </div>
      </div>
      <!-- 验证码登录 -->
      <div v-if="getNodeCode && !retrievePassword" class="getNodeCode">
        <span
          style="
            font-size: 20px;
            color: rgba(34, 34, 34, 0.7);
            line-height: 29px;
            cursor: pointer;
          "
          @click="callBack"
          ><i class="el-icon-arrow-left"></i>返回</span
        >
        <h2 style="font-size: 32px; color: #222222; margin-top: 40px">
          输入手机号验证码
        </h2>
        <span
          style="
            font-size: 21px;
            color: rgba(34, 34, 34, 0.7);
            line-height: 29px;
          "
        >
          请输入发送至手机的6位验证码，并在有效期内填写完成
        </span>
        <div class="nodeCode">
          <el-input
            v-model.number="nodeCode[item - 1]"
            v-for="item in 6"
            :key="item"
            @input="inputChange($event, item)"
            :ref="'nodeCode' + item"
            maxlength="1"
          ></el-input>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            margin-top: 16px;
          "
        >
          <span style="color: #222222">
            手机号已停用？
            <span
              style="color: #2e98fe; cursor: pointer"
              @click="retrievePassword = true"
              >找回账号</span
            >
          </span>
          <span
            style="cursor: pointer; color: #2e98fe; padding-right: 19.2px"
            v-if="nodeCodeTime === 0"
            @click="getNodeCodeTime"
            >重新获取</span
          >
          <span style="color: #222222; padding-right: 19.2px" v-else>
            <span style="color: #2e98fe">{{ nodeCodeTime }}s</span
            >后可重新获取</span
          >
        </div>
        <el-button
          style="
            width: 98%;
            height: 60px;
            color: #fff;
            font-size: 16px;
            margin-top: 80.006px;
            background: #2e98fe;
          "
          :style="{
            background:
              nodeCode.length < 6
                ? '#bbbfc4'
                : !nodeCode.every((item) => typeof item === 'number')
                ? '#bbbfc4'
                : '#2E98FE',
          }"
          @click="nodeCodeSubmit"
          >登录</el-button
        >
      </div>
      <!-- 钉钉登录 -->
      <div v-if="isDD">
        <h2
          style="
            text-align: center;
            font-size: 36px;
            color: #222222;
            line-height: 52px;
          "
        >
          欢迎登录Ylab系统
        </h2>
        <div
          style="
            text-align: center;
            font-size: 20px;
            color: rgba(34, 34, 34, 0.7);
            line-height: 29px;
          "
        >
          请使用钉钉移动端扫描二维码
        </div>
        <div id="login_container"></div>
      </div>

      <!-- 找回密码 -->
      <div
        v-if="retrievePassword"
        style="margin-top: 67.2px"
        class="retrievePassword"
      >
        <span
          style="
            font-size: 20px;
            color: rgba(34, 34, 34, 0.7);
            line-height: 29px;
            cursor: pointer;
          "
          @click="retrievePassword = false"
          ><i class="el-icon-arrow-left"></i>返回</span
        >
        <h2 style="font-size: 32px; color: #222222; margin-top: 40px">
          找回密码
        </h2>
        <el-form ref="retrieveForm" :model="retrieveForm">
          <el-form-item prop="phone">
            <el-input
              v-model="retrieveForm.phone"
              placeholder="请输入您的手机号"
            ></el-input>
          </el-form-item>

          <el-form-item prop="code">
            <el-input v-model="retrieveForm.code" placeholder="请输入验证码">
              <template #suffix>
                <el-button
                  style="
                    width: 109px;
                    height: 64px;
                    color: #fff;
                    font-size: 16px;
                    background: #2e98fe;
                    margin-right: -5px;
                  "
                  @click="affirmSubmit"
                  >获取</el-button
                >
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model="retrieveForm.password"
              placeholder="设置新密码"
              :type="passwordType"
            >
              <template #suffix>
                <i
                  class="el-icon-view"
                  style="
                    margin-right: 19.2px;
                    cursor: pointer;
                    line-height: 63px;
                  "
                  @mouseenter="passwordType = 'text'"
                  @mouseleave="passwordType = 'password'"
                ></i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="affirmPassword">
            <el-input
              v-model="retrieveForm.affirmPassword"
              placeholder="再次输入新密码"
              :type="passwordType"
            >
              <template #suffix>
                <i
                  class="el-icon-view"
                  style="
                    margin-right: 19.2px;
                    cursor: pointer;
                    line-height: 3.3vw;
                  "
                  @mouseenter="passwordType = 'text'"
                  @mouseleave="passwordType = 'password'"
                ></i>
              </template>
            </el-input>
          </el-form-item>
        </el-form>

        <el-button
          style="
            width: 100%;
            height: 60px;
            color: #fff;
            font-size: 16px;
            margin-top: 19.2px;
            background: #2e98fe;
          "
          @click="affirmSubmit"
          >确认修改</el-button
        >
      </div>
    </div>

    <el-drawer size="100vw" title="" :visible.sync="drawer" direction="rtl">
      <div style="width: 1200px; margin: 0 auto">
        <serviceAgreement v-if="text === '服务协议'"></serviceAgreement>
        <privacyPolicy v-else></privacyPolicy>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getCode, loginByCode } from "@/api/login";
import serviceAgreement from "./serviceAgreement.vue";
import privacyPolicy from "./privacyPolicy.vue";
export default {
  name: "Login",
  components: { serviceAgreement, privacyPolicy },
  data() {
    return {
      text: "",
      drawer: false,
      form: {
        phone: "",
        password: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "change" },
          { pattern: /^1[3456789]\d{9}$/, message: "请输入正确手机号" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
        ],
      },
      typeActive: 0,
      passwordType: "password",
      rememberPassword: true,
      read: true,
      isDD: false,
      getNodeCode: false,
      nodeCode: [],
      nodeCodeTime: 59,
      timer: null,
      retrievePassword: false,
      retrieveForm: {
        phone: "",
        code: "",
        password: "",
        affirmPassword: "",
      },
    };
  },
  created() {
    let form = window.localStorage.getItem("password");
    if (form) {
      this.form.phone = JSON.parse(form).phone;
    }
  },
  methods: {
    triggerPhone(index) {
      this.typeActive = index;
      let form = window.localStorage.getItem("password");
      if (form) {
        this.passwordType = "text";
        this.form = JSON.parse(form);
        this.$nextTick(() => {
          this.passwordType = "password";
        });
      } else {
        this.form.password = "";
      }
    },
    // 确认修改
    affirmSubmit() {},
    // 验证码确认
    async nodeCodeSubmit() {
      let res = await loginByCode(this.form.phone, this.nodeCode.join(""));
      if (res.code === 200) {
        this.$store.commit("user/setToken", res.data);
        await this.$store.dispatch("user/getUserInfo");
        this.$message.success("登录成功");
        this.$router.push("/");
      } else {
        this.$message.error("验证码错误");
      }
    },
    // 验证码登录返回
    callBack() {
      this.getNodeCode = false;
      clearInterval(this.timer);
      this.nodeCodeTime = 59;
    },
    // 重新股获取验证码
    getNodeCodeTime() {
      this.nodeCodeTime = 59;
      this.timer = setInterval(() => {
        this.nodeCodeTime--;
        if (this.nodeCodeTime === 0) return clearInterval(this.timer);
      }, 1000);
    },
    // 验证码输入
    inputChange(e, index) {
      if (e) {
        if (index === 6) return;
        let dom = "nodeCode" + (index + 1);
        this.$refs[dom] && this.$refs[dom][0].focus();
      } else {
        if (index === 1) return;
        let dom = "nodeCode" + (index - 1);
        this.$refs[dom] && this.$refs[dom][0].focus();
      }
    },
    // 获取钉钉二维码
    async ddLoginInit() {
      this.isDD = !this.isDD;
      // 钉钉扫码流程：扫码成功登录后会自动跳到这个url页面，url路径会携带code，你拿到这个code，调用登录接口成功就跳转。
      // 你的项目页面
      if (!this.isDD) return;
      await this.$nextTick();
      const url = encodeURIComponent(
        "https://www.yibeirui.com/cloud-flow/user/dingLogin"
        // "http://cloud.yibeirui.com/"
        // "http://192.168.2.53:88/#/home"
      );
      console.log(url);
      // appid 找后端要
      const appid = "dingtsjyfstxyxl7p4u9";
      // 钉钉自己的url 修改上面俩，不需要动这个
      const goto = encodeURIComponent(
        `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}`
      );
      // eslint-disable-next-line no-undef
      DDLogin({
        id: "login_container", // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
        goto: goto, // 请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width: "100%", // 官方参数 365
        height: "500", // 官方参数 400
      });
      const handleMessage = (event) => {
        const origin = event.origin;
        if (origin === "https://login.dingtalk.com") {
          // 判断是否来自ddLogin扫码事件。
          const loginTmpCode = event.data;
          // 获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
          // 此步拿到临时loginTmpCode换取正式code
          window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`;
        }
      };
      if (typeof window.addEventListener !== "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent !== "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    },
    // 提交
    submitForm() {
      console.log(123);
      if (!this.read) return this.$message.error("请勾选用户协议");
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.typeActive === 0) {
            this.getNodeCode = true;
            if (
              process.env.NODE_ENV !== "development" &&
              !window.location.href.includes("http://test")
            ) {
              await getCode(this.form.phone);
            }
            this.timer = setInterval(() => {
              this.nodeCodeTime--;
              if (this.nodeCodeTime === 0) return clearInterval(this.timer);
            }, 1000);
            this.$nextTick(() => {
              this.$refs.nodeCode1 && this.$refs.nodeCode1[0].focus();
            });
            return;
          }

          let res = await this.$store.dispatch("user/getToken", this.form);   
          if (res.code === 200) {
            // 设置记住密码
            if (this.typeActive === 1 && this.rememberPassword) {
              window.localStorage.setItem(
                "password",
                JSON.stringify(this.form)
              );
            }
            this.$router.push("/");
          } else {
            this.$message.info("密码错误");
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.loginPage {
  width: 100vw;
  min-width: 1000px;
  overflow: hidden;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  background: no-repeat 0 0 transparent
    url("https://ybr-test.oss-cn-shanghai.aliyuncs.com/public/images/bg-login.png");
  background-size: 100% 100%;
  align-items: center;
  .login-Form {
    width: 488.006px;
    // height: 548.8px;
    background: #ffffff;
    box-shadow: 0px 0.208vw 1.042vw 0px rgba(171, 206, 253, 0.5);
    border-radius: 10.003px;
    padding: 38.4px;
    box-sizing: border-box;
    position: relative;
    .login-code {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
    .code {
      display: flex;
      flex-direction: column;
      h2 {
        text-align: center;
        margin-top: 0;
        font-size: 36px;
        margin-bottom: 20px;
        color: #222222;
        line-height: 40px;
      }
      .login-type {
        // margin-top: 60px;
        font-size: 20.006px;
        line-height: 34.56px;
        color: rgba(34, 34, 34, 0.7);
        display: flex;
        span {
          cursor: pointer;
          &:nth-child(2) {
            margin-left: 39.994px;
          }
          &.active {
            border-bottom: 2px solid #2e98fe;
            color: #2e98fe;
          }
        }
      }
    }
    .login-phone {
      display: flex;
      align-items: center;
      height: 63.994px;
      background: #f0f7ff;
      border-radius: 4px;
      span {
        width: 63.994px;
        color: #222222;
        font-size: 20.006px;
        margin-left: 20.006px;
        border-right: 2px solid #aeb9c9;
      }
      /deep/ .el-input__inner {
        background: #f0f7ff;
        border: none;
      }
    }
    .login-password {
      display: flex;
      flex-direction: column;
      padding-top: 39.994px;

      .account,
      .password {
        display: flex;
        align-items: center;
        background-color: #f0f7ff;
        border-radius: 4px;
        height: 63.994px;
        .el-image {
          margin-left: 20.006px;
          border-right: 2px solid #aeb9c9;
          padding-right: 19.2px;
        }
        /deep/ .el-input__inner {
          background: #f0f7ff;
          border: none;
        }
      }
    }
    .rememberPassword {
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      span {
        &:nth-child(1) {
          color: #344666;
        }
        &:nth-child(2) {
          color: #2e98fe;
          cursor: pointer;
        }
      }
    }
  }
  /deep/ .el-form-item {
    margin-bottom: 0;
  }
  .nodeCode {
    display: flex;
    justify-content: space-between;
    margin-top: 65.28px;
    /deep/ .el-input__inner {
      width: 40px;
      height: 40px;
    }
  }
  .retrievePassword {
    .el-form-item {
      margin-bottom: 19.2px;
      /deep/ .el-input__inner {
        background: #f0f7ff;
        border: none;
        height: 63.994px;
      }
    }
  }
}
</style>